import React from 'react'
import { BasicContextState, CacheReset, ContextConfig, GraphContextState, InfiniteScrollConfig, VersionedContextState } from './context.d'
import { AnyAction, Dispatch, LoadDetailsAction, LoadIdsAction,
	ApiRequestFailedAction, ApiRequestSucceededAction, PrimaryAction, ClearCacheAction, FullApiResponseAction,
	ApiResponseAction } from './action/Action.d'
import { actionKey, LOAD_DETAILS_ACTION_TYPES, LOAD_IDS_ACTION_TYPES, setInProgress } from './action/action'
import { InfiniteScrollContextState } from '../common/InfiniteScroll.d'
import { AppContextState } from '../contexts/AppContext.d'
import { DispatchToApi } from './dispatch.d'
import { AUTOSAVE_INTERVAL_FAILED } from '../Write/config'

export const defaultContextConfig: InfiniteScrollConfig & ContextConfig = {
	// context: null,
	contextDataLocation: [],
	localStorageLocation: 'resource',
	retainedOnCacheClear: {},
	idField: 'id',
	apiEndpointIds: '/resources',
	apiEndpointDetails: '/resource',
	defaultState: {},
	freshState: {
		failed: {},
		inProgress: {}
	},
	actionTypes: {
		loadDetails: [],
		loadIds: []
	},
	// eslint-disable-next-line react/display-name
	renderThumbnail: id => <div key='id' style={{ height: '50px' }}>Item #{id}</div>
}

export const resolveFreshState = <S extends BasicContextState>(clear: CacheReset<S>, state: S, appState?: AppContextState): S =>
	typeof clear === 'function' ? clear(state, appState) : clear

/** If appState.dataVersion is set to this specific value, it will trigger all other contexts to clear their states. */
export const FORCE_CACHE_CLEAR_VERSION = -1

export const initialiseState = <S extends BasicContextState = BasicContextState>(
	config: ContextConfig<S>,
	appState: AppContextState
): () => S =>
		() => {
			// Load data from local storage if available
			const startState = localStorage.getItem(config.localStorageLocation)
				? JSON.parse(localStorage.getItem(config.localStorageLocation) || '{}') as S
				: { ...defaultContextConfig.defaultState, ...config.defaultState }
			// Reset the state as per the context config
			const freshState: S = appState?.offlineMode
				? resolveFreshState(defaultContextConfig.freshState, startState, appState) as S
				: {
					...defaultContextConfig.freshState,
					...resolveFreshState(config.freshState, startState, appState)
				}
			if (appState.dataVersion?.latest === FORCE_CACHE_CLEAR_VERSION) {
				return resolveFreshState(config.retainedOnCacheClear, startState, appState)
			}
			return { ...startState, ...freshState, actionStatus: {} }
		}

export const checkCacheValidity = (state: VersionedContextState, dispatch: Dispatch<PrimaryAction>, appState: AppContextState): void => {
	if (!state.dataVersion && appState.dataVersion?.latest) {
		dispatch({
			type: 'RECEIVE_DATA_VERSION',
			version: appState.dataVersion.latest,
			redispatch: dispatch
		})
	} else if (state.dataVersion && appState.dataVersion?.latest && (
		state.dataVersion < appState.dataVersion.latest || (
			appState.dataVersion.latest === FORCE_CACHE_CLEAR_VERSION && state.dataVersion !== FORCE_CACHE_CLEAR_VERSION))) {
		dispatch({
			type: 'CLEAR_CACHE',
			version: appState.dataVersion?.latest,
			redispatch: dispatch
		})
	}
}

const treatDetailsRequestAction = <S extends BasicContextState = BasicContextState>(
	state: S, action: FullApiResponseAction<LoadDetailsAction>, config = defaultContextConfig) => {
	const items = Array.isArray(action.apiResponse.data)
		? action.apiResponse.data : [action.apiResponse.data]

	if (!config.contextDataLocation[0]) {
		throw Error('No contextDataLocation found')
	}
	state[config.contextDataLocation[0]] = state[config.contextDataLocation[0]] || {}
	for (const item of items) {
		if (typeof item !== 'object' || !item) {
			console.error(`Unexpected item in API response: ${String(item)}`)
			continue
		}
		if (!item[config.idField]) {
			console.error(`No '${config.idField}' field in API response object`)
			continue
		}
		state[config.contextDataLocation[0]][item[config.idField].toString()] = item
		for (const field in config.secondaryIdFields) {
			if (!item[field]) {
				continue
			}
			if (!(config.secondaryIdFields[field] in state)) {
				state[config.secondaryIdFields[field]] = {}
			}
			state[config.secondaryIdFields[field]][item[field]] = item[config.idField]
		}
	}

	if (action.sourceAction?.items && items.length < action.sourceAction.items.length) {
		for (const idNotReturned of action.sourceAction.items) {
			if (!items.some(i => typeof i === 'object' && i?.[config.idField] === idNotReturned)) {
				delete state[config.contextDataLocation[0]][idNotReturned.toString()]
			}
		}
	}

	delete state.inProgress?.[actionKey(action.sourceAction)]
	state.actionStatus = state.actionStatus || {}
	state.actionStatus[actionKey(action.sourceAction)] = 'complete'
	return { ...state }
}

const treatIdsRequestAction = <S extends InfiniteScrollContextState = InfiniteScrollContextState>(
	state: S, action: FullApiResponseAction<LoadIdsAction>) => {
	const apiResponseData = action.apiResponse.data as number | number[]
	const apiResponse: number[] = Array.isArray(apiResponseData)
		? apiResponseData : [apiResponseData]
	const key = actionKey({ ...action.sourceAction, offset: undefined })

	state.idsByQuery = state.idsByQuery || {}
	state.idsByQuery[key] = state.idsByQuery[key] || []
	// Append to the already-known IDs, unless the page was refreshed since data was last retrieved
	state.idsByQuery[key] = state.actionStatus?.[key]
		? state.idsByQuery[key].concat(apiResponse)
		: apiResponse

	state.actionStatus = {
		...state.actionStatus,
		[key]: action.apiResponse.queryComplete ? 'complete' : 'querying'
	}
	delete state.inProgress?.[key]
	return { ...state }
}

const clearSiteCaches = <S extends VersionedContextState = VersionedContextState>(
	state: S, action: ClearCacheAction, contextConfig: ContextConfig): S => {
	// Reset the whole state, back to (almost) nothing
	if (action.version === FORCE_CACHE_CLEAR_VERSION) {
		return {
			...resolveFreshState(contextConfig.retainedOnCacheClear, state) as S,
			actionStatus: {},
			dataVersion: action.version
		}
	}
	// Simply reset actionStatus, prompting all queries to be redownloaded when needed
	return {
		...state,
		actionStatus: {},
		dataVersion: action.version
	}
}

// Either once an action has come back from API, or doesn't involve an API call.
export const treatLocalAction = <S extends BasicContextState = BasicContextState>(
	state: S, action: AnyAction, config = defaultContextConfig): S => {
	if ('apiResponse' in action) {
		return treatApiResponseAction(state, action, config)
	} else {
		return treatLocalPrimaryAction(state, action, config)
	}
}

const treatApiResponseAction = <S extends BasicContextState = BasicContextState>(
	state: S, action: ApiResponseAction, config = defaultContextConfig): S => {
	if (action.apiResponse?.version) {
		// On any response from the API, check if the site's data has been updated.
		setTimeout(() => action.receiveDataVersion?.(action), 1)
	}

	// Receiving in-depth details for one or many items.
	if (LOAD_DETAILS_ACTION_TYPES.includes(action.type)) {
		return treatDetailsRequestAction(state, action as FullApiResponseAction<LoadDetailsAction>, config)

		// Receiving multiple items' IDs only.
	} else if (LOAD_IDS_ACTION_TYPES.includes(action.type)) {
		return treatIdsRequestAction(state, action as FullApiResponseAction<LoadIdsAction>)
	} else if (action.type.startsWith('GRAPH_')) {
		delete state.inProgress?.[actionKey(action.sourceAction)]
		return {
			...state,
			graphs: {
				...(state as GraphContextState).graphs,
				[actionKey(action.sourceAction)]: 'data' in (action.apiResponse || {}) ? action.apiResponse?.data as string : null
			}
		}
	}

	// On any API request success, do things like removing failure logs for the request.
	if (action.type === 'API_REQUEST_SUCCEEDED') {
		return treatSuccessfulApiRequest(state, action)
	} else if (action.type === 'API_REQUEST_FAILED') {
		return treatFailedApiRequest(state, action, config)
	}

	return state
}

const treatLocalPrimaryAction = <S extends BasicContextState = BasicContextState>(
	state: S, action: PrimaryAction, config = defaultContextConfig): S => {
	switch (action.type) {
	case 'SET_IN_PROGRESS':
		// If a timer would reset actionStatus after a while, cancel it.
		if (typeof state.inProgress?.[actionKey(action.key)] === 'number') {
			clearTimeout(state.inProgress?.[actionKey(action.key)])
		}
		if (action.status === undefined) {
			delete state.inProgress?.[actionKey(action.key)]
			return { ...state }
		}
		return {
			...state,
			inProgress: {
				...state.inProgress,
				[ actionKey(action.key) ]: action.status
			}
		}
	case 'CLEAR_ACTION_STATUS':
		delete state.actionStatus?.[actionKey(action.actionToClear)]
		return { ...state }

	case 'CLEAR_CACHE':
		return clearSiteCaches(state, action, config)

	case 'CLEAR_GRAPH':
		if (!('graphs' in state))
			return state
		delete(state as GraphContextState).graphs?.[action.graph]
		return { ...state }

	case 'CLEAR_FAILED_ACTION':
		delete state?.failed?.[actionKey(action.action)]
		if ('completedIdsByQuery' in state) {
			delete(state as unknown as { completedIdsByQuery: Record<string, unknown>})
				.completedIdsByQuery?.[actionKey(action.action)]
		}
		return { ...state }

	case 'RECEIVE_DATA_VERSION':
		return { ...state, dataVersion: action.version }

	default:
		return state
	}
}

const treatLoadDetailsAction = (action: LoadDetailsAction, dispatchToApi: DispatchToApi, config: ContextConfig): void | Promise<void> => {
	if (Array.isArray(action.items)) {
		const idField = typeof config.secondaryIdFields === 'object' && config.idField in config.secondaryIdFields
			? `&id-field=${config.idField}` : ''
		return dispatchToApi(`${config.apiEndpointDetails}?ids=${action.items.join(',')}${idField}`)
	} else {
		return dispatchToApi(`${config.apiEndpointDetails}/${action[config.idField]}`)
	}
}

const treatLoadIdsAction = (action: LoadIdsAction, dispatchToApi: DispatchToApi, config: ContextConfig): void | Promise<void> => {
	const offset = action.offset ? `&offset=${action.offset}` : ''
	const caseSensitive = action.caseSensitive ? '&caseSensitive=1' : ''
	if (action.value && action.comparison) {
		return dispatchToApi(`${config.apiEndpointIds}?field=${action.field}` +
			`&comparison=${action.comparison}&value=${encodeURIComponent(action.value)}${caseSensitive}${offset}`)
	} else if (action.value) {
		return dispatchToApi(`${config.apiEndpointIds}?field=${action.field}&value=${action.value}${caseSensitive}${offset}`)
	} else {
		return dispatchToApi(`${config.apiEndpointIds}?field=${action.field}${caseSensitive}${offset}`)
	}
}

const treatSuccessfulApiRequest = <S extends BasicContextState = BasicContextState>(state: S, action: ApiRequestSucceededAction): S => {
	const sourceAction = actionKey(action.sourceAction)
	delete state.failed?.[sourceAction]
	if (action.sourceAction.statusResetDelay) {
		state.inProgress = state.inProgress || {}
		const delayedAction = action.sourceAction.statusResetDelayFor
			? actionKey(action.sourceAction.statusResetDelayFor)
			: sourceAction
		if (delayedAction !== sourceAction && typeof state.inProgress[sourceAction] !== 'number') {
			delete state.inProgress?.[sourceAction]
		}
		if (typeof state.inProgress[delayedAction] === 'number') {
			clearTimeout(state.inProgress[delayedAction])
		}
		const timerId = setTimeout(
			() => setInProgress(delayedAction, undefined, action.redispatch),
			action.sourceAction.statusResetDelay * 1000)
		state.inProgress[delayedAction] = timerId
	} else {
		delete state.inProgress?.[sourceAction]
	}
	return { ...state }
}

const treatFailedApiRequest = <S extends InfiniteScrollContextState = InfiniteScrollContextState>(state: S,
	action: ApiRequestFailedAction,
	config: ContextConfig | (ContextConfig & InfiniteScrollConfig)): S => {
	const sourceActionKey = actionKey(action.sourceAction)
	if (action.status >= 400 && action.status < 500) {
		state.failed = {
			...state.failed,
			[sourceActionKey]: action.status
		}
	}
	if (state.inProgress && sourceActionKey in state.inProgress) {
		state.inProgress[sourceActionKey] = Number(setTimeout(
			() => action.redispatch({ type: 'SET_IN_PROGRESS', key: sourceActionKey } as PrimaryAction),
			AUTOSAVE_INTERVAL_FAILED * 1000))
	}
	if (action.status === 404 && 'renderThumbnail' in config) {
		state.actionStatus = {
			...state.actionStatus,
			[actionKey({ ...action.sourceAction, offset: undefined })]: 'complete'
		}
	}
	return { ...state }
}

/** Defaults for when any action is first dispatched. */
export const treatApiAction = (action: AnyAction, dispatch: Dispatch<AnyAction>,
	dispatchToApi: DispatchToApi, config = defaultContextConfig): void => {
	if (LOAD_IDS_ACTION_TYPES.includes(action.type)) {
		void treatLoadIdsAction(action as LoadIdsAction, dispatchToApi, config)
	} else if (LOAD_DETAILS_ACTION_TYPES.includes(action.type)) {
		void treatLoadDetailsAction(action as LoadDetailsAction, dispatchToApi, config)
	} else {
		dispatch(action)
	}
}
