import { getCurrentDate, getEntry, getEntryId, getParamDate, getParamEntryId,
	hasParamDate, isEntryBlank, isInterimEntry, lastEntryOnDay } from './helpers'
import { getIdsAction, startNewEntry, getEntriesAction, initialiseInterimEntry } from './actions'
import { actionKey, conditionalDelayedDispatch, conditionalDispatch } from '../helpers/action/action'
import { AUTOSAVE_INTERVAL_FAILED, AUTOSAVE_INTERVAL_NORMAL, AUTO_EDIT_RECENT_ENTRIES, TOMORROW_STARTS_AT } from './config'
import { AppContextState, LoginScope, UserStateChecker } from '../contexts/AppContext.d'
import { EntryContextState } from '../contexts/EntryContext.d'
import { EntryAction } from '../helpers/action/EntryAction.d'
import { calculateIntervalBetween } from '../helpers/format'
import { appRefreshedAt } from '../contexts/app/auth'
import { Dispatch } from '../helpers/action/Action.d'
import { History, Location } from 'history'
import { Entry } from '../Entry/Entry.d'

/** Run directly from the EntryContext every render */
export const contextLevelWriteActions = (entries: EntryContextState, dispatch: Dispatch<EntryAction>,
	location: Location, history: History, hasLoginScope: UserStateChecker<LoginScope>, appState: AppContextState): void => {
	saveAllEntries(entries, dispatch)
	refreshCurrentEntry(entries, dispatch, appState)
	setupCurrentEntry(entries, location, history, dispatch, hasLoginScope)
}

const refreshCurrentEntry = (entries: EntryContextState, dispatch: Dispatch<EntryAction>, appState: AppContextState): void => {
	const timestamp = appRefreshedAt(appState)
	if (timestamp && timestamp !== entries.refreshedAt) {
		dispatch({ type: 'ENTRIES_MARK_REFRESHED', timestamp })
		if (entries.currentlyWriting && !isInterimEntry(entries.currentlyWriting)) {
			dispatch({ type: 'CLEAR_ACTION_STATUS', actionToClear: actionKey(getEntriesAction(entries.currentlyWriting)) })
		}
	}
}

/** Dispatch actions to evolve the current state as appropriate */
const setupCurrentEntry = (state: EntryContextState,
	location: Location,
	history: History,
	dispatch: Dispatch<EntryAction>,
	hasLoginScope: UserStateChecker<LoginScope>): void => {
	const paramEntryId = getParamEntryId(location)
	const entryId = getEntryId(location, state)
	const entry = getEntry(state, entryId)
	const date = getCurrentDate(location, entry)
	const lastEntryToday = lastEntryOnDay(state, date)

	const idsActionKey = JSON.stringify(getIdsAction(date))

	// Redirect if specified by state.
	if (applyRedirects(state, location, history, dispatch)) {
		/* First things first: just wipe/apply the redirects */

	// Redirect if we're pointing at an invalid entry.
	} else if (!entry.id && (Number.isNaN(paramEntryId) || isInterimEntry(paramEntryId))) {
		setTimeout(() => history.push('/write'))

	// If we've specified an entry ID but the state doesn't yet reflect that.
	} else if (paramEntryId && paramEntryId !== state.currentlyWriting) {
		startNewEntry(dispatch, entryId)

	// Load details of a specific entry if provided with an ID in the URL.
	} else if (!entry.id && paramEntryId && entryId && !isInterimEntry(entryId)) {
		conditionalDispatch(getEntriesAction(entryId), state, dispatch)

	// Initialise a brand new entry: create interim state locally.
	} else if (entryId === undefined && !lastEntryToday) {
		initialiseInterimEntry(dispatch, date)

	} else if (!entryId && isInterimEntry(lastEntryToday)) {
		startNewEntry(dispatch, lastEntryToday)

	// Automatically start editing entries which are unprocessed or very new.
	} else if (lastEntryToday // some entry exists to be viewed
		&& (Math.abs(calculateIntervalBetween(date)) / 60 / 60 < AUTO_EDIT_RECENT_ENTRIES                  // recent
			|| ['new', 'modified'].includes(getEntry(state, lastEntryToday).status || '')                  // modified, saved
			|| (state.write && lastEntryToday in state.write))                                             // modified, unsaved
		&& (!entry.id || (lastEntryToday != entryId && isInterimEntry(entryId) && isEntryBlank(entry)))) { // no current entry
		startNewEntry(dispatch, lastEntryToday)

	// Wipe currentlyWriting if it doesn't refer to any entry in state.
	} else if (state.currentlyWriting
		&& !state.entries?.[state.currentlyWriting]
		&& !state.write?.[state.currentlyWriting]) {
		startNewEntry(dispatch)

	// Start a new entry when time has moved onto a new day.
	} else if (entry.saveStatus === 'saved' && hasLoginScope('auth0') &&
		!paramEntryId && !isInterimEntry(entryId) &&
		calculateIntervalBetween(entry.date) / 3600 > 24 + TOMORROW_STARTS_AT) {
		startNewEntry(dispatch)

	// Start a new entry when the user just switched to a new day.
	} else if (hasParamDate(location) && entryId && !paramEntryId
		&& new Date(entry.date || '-').getTime() !== getParamDate(location)?.getTime()) {
		startNewEntry(dispatch)

	// Load IDs and/or details of the current day's entries if missing.
	} else {
		conditionalDispatch(getIdsAction(date), state, dispatch)

		const entriesToday = state.idsByQuery?.[idsActionKey] || []
		if (entriesToday.length > 0) {
			const entryDetailsAction = getEntriesAction(entriesToday)
			conditionalDispatch(entryDetailsAction, state, dispatch)
		}
	}
}

/** After saving an entry, we should redirect to that entry's page */
const applyRedirects = (entries: EntryContextState, location: Location, history: History, dispatch: Dispatch<EntryAction>): boolean => {
	if (!entries.redirectFrom && !entries.redirectTo) {
		return false
	}
	if (entries.redirectTo && (!entries.redirectFrom || getParamEntryId(location) === entries.redirectFrom)) {
		setTimeout(() => {
			history.replace(`/write?entryId=${entries.redirectTo}`)
			dispatch({ type: 'CLEAR_ENTRY_REDIRECTS' })
		}, 1)
	} else {
		dispatch({ type: 'CLEAR_ENTRY_REDIRECTS' })
	}
	return true
}

/** Trigger Save (or Delete) actions as appropriate for all currently-pending entries */
export const saveAllEntries = (entries: EntryContextState, dispatch: Dispatch<EntryAction>): void => {
	if (!entries.write)
		return
	for (const entryId of Object.keys(entries.write)) {
		if (typeof entries.write[Number(entryId)] === 'object') { // old versions had weird state
			saveEntry(entries.write[Number(entryId)], entries, dispatch)
		}
	}
}

/** Save or delete an entry, assuming no such action is already underway */
const saveEntry = (entry: Entry, entries: EntryContextState, dispatch: Dispatch<EntryAction>): void => {
	// No action needs to be taken in these cases
	if (entry.saveStatus === 'saved' || entry.saveStatus === 'deleted') {
		return
	}

	// If the entry's marked to be deleted, send the API request to do so
	if (entry.saveStatus === 'deleting') {
		conditionalDispatch({ type: 'SEND_DELETE_WRITE_ENTRY', entryId: entry.id }, entries, dispatch)

	// If the entry's marked to be saved, send the API request to do so
	} else if (entry.saveStatus === 'saving') {
		conditionalDispatch(
			{ type: 'SAVE_WRITE_ENTRY', entryId: entry.id },
			entries,
			dispatch)

	// If the entry's been altered, trigger an autosave timer
	} else {
		conditionalDelayedDispatch(
			{ type: 'SAVE_WRITE_ENTRY', entryId: entry.id },
			entries,
			dispatch,
			entry.saveStatus === 'failed' ? AUTOSAVE_INTERVAL_FAILED : AUTOSAVE_INTERVAL_NORMAL)
	}
}
