import React, { createContext, useEffect, useState } from 'react'
import { AppReducer, AppDispatcher } from '../reducers/AppReducer'
import { catchShortcuts, cacheCurrentPage } from '../helpers/path'
import { getJwt, hasLoginScope, isUserType } from './app/auth'
import { useLocation, useHistory } from 'react-router-dom'
import { ChildrenOnlyProps } from '../helpers/context.d'
import { generateDispatcher } from '../helpers/dispatch'
import { useAuth0 } from '@auth0/auth0-react'
import { AppContextProvided, AppContextState } from './AppContext.d'
import config from '../common/auth_config.json'
import { History } from 'history'
import PropTypes from 'prop-types'
import { specialLoginActions } from '../site/Login'
import { SuperDispatch } from '../helpers/dispatch.d'
import { saveUserFields, specialSyncActions } from '../Settings/controller'
import { receiveDataVersion, regularlyCheckToday } from './app/dispatchers'

export const baseAppContextProvided: AppContextProvided = {
	appState: {
		paths: {}
	},
	appDispatch: () => { throw new Error('Using dummy app context') },
	hasLoginScope: () => { throw new Error('Using dummy app context') },
	isUserType: () => { throw new Error('Using dummy app context') },
	getJwt: () => { throw new Error('Using dummy app context') },
	setShortcutsActive: () => { throw new Error('Using dummy app context') },
	receiveDataVersion: () => { throw new Error('Using dummy app context') }
}

export const AppContext = createContext<AppContextProvided>(baseAppContextProvided)

const createAppReducer = () => React.useReducer(AppReducer, [], () => {
	const initialState: AppContextState = {
		dataVersion: {},
		...JSON.parse(localStorage.getItem('appState') || '{}'),
		paths: {},
		pages: {},
		inProgress: {},
		failed: {},
		loggingOut: undefined
	}
	delete initialState.user?.updated
	if (!initialState.user && initialState.disableAuth) { initialState.user = config.dummyAuthState }
	return initialState
})

const AppContextProvider = (props: ChildrenOnlyProps): JSX.Element => {
	const path = useLocation().pathname
	const history: History = useHistory()

	// Initialise.
	const [appState, appReducerDispatch] = createAppReducer()
	const auth0 = useAuth0()
	const appDispatch = generateDispatcher(
		appReducerDispatch,
		AppDispatcher as SuperDispatch,
		{ appState, getJwt: getJwt(appState, auth0), appDispatch: appReducerDispatch }
	)
	const hasLoginScopeFunction = hasLoginScope(appState, auth0)

	useEffect(() => localStorage.setItem('appState', JSON.stringify(appState)), [appState])

	// Keep track of when the app is actually being used, to recheck data after an absence.
	useEffect(() => {
		const setWindowFocused = () => appDispatch({ type: 'SET_WINDOW_FOCUSED', focused: true })
		const setWindowNotFocused = () => appDispatch({ type: 'SET_WINDOW_FOCUSED', focused: false })
		window.addEventListener('focus', setWindowFocused)
		window.addEventListener('blur', setWindowNotFocused)
		return () => {
			window.removeEventListener('focus', setWindowFocused)
			window.removeEventListener('blur', setWindowNotFocused)
		}
	})

	useEffect(regularlyCheckToday(appState, appDispatch), [])

	// Listen to keyboard shortcuts to change page.
	const [shortcutsActive, setShortcutsActive] = useState(true)
	useEffect(catchShortcuts(shortcutsActive, history, path, appState, hasLoginScopeFunction),
		[shortcutsActive, history, path, appState])

	// Note down whenever we load a new page.
	cacheCurrentPage(path, appState, appDispatch)

	specialLoginActions(appState, auth0, appDispatch)
	specialSyncActions(appState, appDispatch)
	saveUserFields(appState, appDispatch)

	return (
		<AppContext.Provider value={{
			appState,
			appDispatch,
			hasLoginScope: hasLoginScopeFunction,
			isUserType: isUserType(appState, auth0),
			getJwt: getJwt(appState, auth0),
			setShortcutsActive,
			receiveDataVersion: receiveDataVersion(appState, appDispatch)
		}}>
			{props.children}
		</AppContext.Provider>
	)
}

AppContextProvider.propTypes = {
	children: PropTypes.object.isRequired
}

export default AppContextProvider
