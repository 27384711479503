import React, { Context, createContext, useContext, useEffect, useReducer } from 'react'
import { CacheReset, ChildrenOnlyProps, ContextConfig } from '../helpers/context.d'
import { EntryContextProvided, EntryContextState } from './EntryContext.d'
import { defaultContextConfig, initialiseState, checkCacheValidity } from '../helpers/context'
import { InfiniteScrollContextProvided } from '../common/InfiniteScroll.d'
import { EntryReducer, EntryDispatcher } from '../reducers/EntryReducer'
import { InfiniteScrollConfig } from '../helpers/context.d'
import { generateDispatcher } from '../helpers/dispatch'
import { SuperDispatch } from '../helpers/dispatch.d'
import EntryThumbnail from '../Entry/EntryThumbnail'
import { AppContext } from '../contexts/AppContext'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { AnyAction, Dispatch, Id } from '../helpers/action/Action.d'
import { contextLevelWriteActions } from '../Write/controller'
import { getIdsAction } from '../Write/actions'

const defaultContextProvided: EntryContextProvided = {
	state: {
		entries: {},
		write: {}
	},
	dispatch: () => { throw new Error('Dummy EntryContext used') }
}

export const EntryContext = createContext<EntryContextProvided>(defaultContextProvided)

const retainedOnCacheClear: CacheReset<EntryContextState> = (state: EntryContextState) => {
	const newState: EntryContextState = {
		currentlyWriting: state.currentlyWriting,
		entries: {},
		write: state.write || {},
		idsByQuery: {}
	}
	const retainedIds = Object.keys(state.write || {}).map(id => parseInt(id))
	if (state.currentlyWriting && !retainedIds.includes(state.currentlyWriting)) {
		retainedIds.push(state.currentlyWriting)
	}
	if (retainedIds.length === 0 || !state.entries || !newState.entries) {
		return newState
	}
	for (const entryId of retainedIds) {
		if (state.entries && entryId in state.entries) {
			newState.entries[entryId] = state.entries[entryId]
		}
		const date = (state.entries[entryId] || state.write?.[entryId])?.date
		const dateIndex = JSON.stringify(getIdsAction(date))
		if (date && newState.idsByQuery && state.idsByQuery && dateIndex in state.idsByQuery) {
			newState.idsByQuery[dateIndex] = state.idsByQuery[dateIndex]
		}
	}
	return newState
}

export const contextConfig: ContextConfig<EntryContextState> & InfiniteScrollConfig = {
	...defaultContextConfig,
	context: EntryContext as unknown as Context<InfiniteScrollContextProvided>,
	localStorageLocation: 'entries',
	contextDataLocation: ['entries', 'write'],
	secondaryIdFields: { order: 'entryOrder' },
	apiEndpointIds: 'entries',
	apiEndpointDetails: 'entry',
	freshState: { entryOrder: {} },
	retainedOnCacheClear,
	actionTypes: {
		loadDetails: ['LOAD_ENTRIES_BY_IDS', 'LOAD_ENTRIES_BY_ORDERS'],
		loadIds: ['QUERY_ENTRIES']
	},
	// eslint-disable-next-line react/display-name
	renderThumbnail: (entryId: Id) =>
		<EntryThumbnail key={entryId} entryId={entryId} />
	// eslint-disable-next-line react/display-name
	// renderDetails: (entryId: Id) =>
	// 	<EntryContent key={entryId} entryId={entryId} />
}

const EntryContextProvider = (props: ChildrenOnlyProps): JSX.Element => {
	const appContext = useContext(AppContext)

	// Initialise.
	const [state, reducerDispatch] = useReducer<typeof EntryReducer, EntryContextState>(EntryReducer, defaultContextProvided.state,
		initialiseState(contextConfig, appContext.appState))

	const dispatch = generateDispatcher(reducerDispatch as Dispatch<AnyAction>, EntryDispatcher as SuperDispatch, appContext)
	checkCacheValidity(state, dispatch, appContext.appState)

	const location = useLocation()
	const history = useHistory()
	contextLevelWriteActions(state, dispatch, location, history, appContext.hasLoginScope, appContext.appState)

	// Save any updates in local storage.
	useEffect(() => localStorage.setItem('entries', JSON.stringify(state)), [state])

	return (
		<EntryContext.Provider value={{ state, dispatch }}>
			{props.children}
		</EntryContext.Provider>
	)
}
EntryContextProvider.propTypes = {
	children: PropTypes.node.isRequired
}

export default EntryContextProvider
